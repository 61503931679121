import React, { useContext } from 'react'
import Layout from '../../components/Global/Layout'
import Loader from '../../components/Global/Loader'
import Legal from '../../components/Legal'
import { GlobalStateContext } from '../../context/GlobalContextProvider'
import { graphql } from 'gatsby'

export const query = graphql`
  {
    allContentfulPrivacyPolicy {
      edges {
        node {
          id
          content {
            json
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const TermsConditions = (props) => {
  const state = useContext(GlobalStateContext)
  const { allContentfulPrivacyPolicy } = props.data

  if (!state.locale) {
    return (
      <Layout locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  const termsConditionsData = allContentfulPrivacyPolicy?.edges.filter(
    (node) => node.node.node_locale === state.locale
  )

  return (
    <Layout
      locale={state.locale}
      title={
        JSON.parse(termsConditionsData[0].node?.seoContent.internal.content)
          .title
      }
      desc={
        JSON.parse(termsConditionsData[0].node?.seoContent.internal.content)
          .desc
      }
    >
      <Legal title='Privacy Policy' data={termsConditionsData} />
    </Layout>
  )
}

export default TermsConditions
